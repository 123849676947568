import classNames from "classnames";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
} from "react";
import Feedback from "./Feedback";
import Hint from "./Hint";
import Label, { LabelTextStyle } from "./Label";
import { Status } from "./types";

export type Props = {
  type?: Extract<
    HTMLInputTypeAttribute,
    "text" | "email" | "tel" | "password" | "number" | "hidden"
  >;
  label?: string;
  hint?: string;
  status?: Status;
  feedback?: ReactNode;
  subLabel?: ReactNode;
  leftIcon?: ReactNode;
  leftIconClickable?: boolean;
  rightIcon?: ReactNode;
  rightIconClickable?: boolean;
  border?: "normal" | "thick";
  placeholderSize?: "medium" | "small";
  labelTextStyle?: LabelTextStyle;
  suppressHotjar?: boolean;
  isOptional?: boolean;
} & ComponentPropsWithoutRef<"input">;

// eslint-disable-next-line no-underscore-dangle
const _Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      type = "text",
      id,
      label = "",
      hint = "",
      disabled,
      readOnly,
      status = "default",
      feedback = "",
      subLabel = "",
      leftIcon,
      leftIconClickable = false,
      rightIcon,
      rightIconClickable = false,
      border = "normal",
      placeholderSize = "medium",
      labelTextStyle = "gray",
      className,
      suppressHotjar,
      isOptional = false,
      hidden,
      onChange,
      ...props
    },
    ref
  ) => {
    const statusColor: Record<string, string> = {
      default:
        "border-other-light-100 focus:border-primary-bold focus:shadow-primary-bold/8",
      error: "border-error focus:border-error focus:shadow-error/8",
    };

    const inputClassName = classNames(
      "body-3 form-input font-themed-weight-3 bg-white rounded-lg w-full focus:outline-none focus:ring-0 focus:shadow-input placeholder-gray-500",
      {
        "disabled:bg-background-light-300 disabled:border-other-light-100":
          disabled,
      },
      {
        "read-only:bg-background-light-300 read-only:border-other-light-100":
          readOnly,
      },
      {
        "pl-8": leftIcon,
      },
      {
        "pr-8": rightIcon,
      },
      {
        border: border === "normal",
      },
      {
        "border-2 focus:border-2": border === "thick",
      },
      {
        "placeholder:text-sm": placeholderSize === "small",
      },
      statusColor[status] || statusColor.default
    );

    return hidden ? null : (
      <div className={className}>
        <div className="flex flex-row justify-between">
          <div className="flex-initial text-text-light-100 caption-1">
            {label && (
              <Label
                id={id}
                label={label}
                textStyle={labelTextStyle}
                showOptional={isOptional}
              />
            )}
          </div>
        </div>
        <div className="relative py-1">
          {leftIcon && (
            <span
              className={classNames(
                "absolute inset-y-2 left-0 flex items-center pl-2 h-8 w-8 text-text-light-500",
                { "pointer-events-none": !leftIconClickable }
              )}
            >
              {leftIcon}
            </span>
          )}

          <input
            id={id}
            className={inputClassName}
            type={type}
            disabled={disabled}
            readOnly={readOnly}
            ref={ref}
            data-hj-allow={(!suppressHotjar && type === "text") || undefined}
            onChange={(e) => {
              e.target.value = e.target.value.trimStart();
              if (onChange) onChange(e);
            }}
            {...props}
          />

          {rightIcon && (
            <span
              className={classNames(
                "absolute inset-y-2 right-0 flex items-center pr-1.5 h-8 w-8 text-text-light-200",
                { "pointer-events-none": !rightIconClickable }
              )}
            >
              {rightIcon}
            </span>
          )}
        </div>
        <div className="flex items-start">
          {hint && <Hint hint={hint} align="left" />}
        </div>
        {feedback && <Feedback status={status}>{feedback}</Feedback>}
        {subLabel}
      </div>
    );
  }
);

export default _Input;
